<template>
	<BaseDropdown
		class="select"
        :class="{'select--full-width' : isFullWidth}"
        :style-list="styleListDropdown"
        v-bind="$attrs"
        @changeVisible="onDropdown"
	>
		<template #preview>
			<BaseButton
				:active="localOpen"
				class="select-preview"
                :class="{'select-preview--full-width' : isFullWidth}"
			>
				<span class="select-preview__label" :title="title">
					{{title}}
				</span>

				<div class="select-preview__arrow">
					<img src="@/assets/images/icons/arrow.svg">
				</div>
			</BaseButton>
		</template>

		<template #content>
			<div class="select-dropdown">
				<div class="select-dropdown__triangle" />
				<!-- HEADER -->
				<div class="select-dropdown__header">
					<div class="select-dropdown__top">
						<slot name="header" :select-all="onSelectAll" :clear="onClear"/>
					</div>
				</div>

				<!-- SEARCH -->
				<div
					v-if="canSearch"
					class="select-dropdown__search"
				>
					<input
						v-model="searchValue"
						type="text"
						placeholder="Поиск"
					>
				</div>

				<!-- OPTIONS -->
				<div class="select-options">
					<div
						v-for="(option, index) of filteredOptions"
						:key="index"
						class="select-options__item"
						:class="getOptionClasses(option)"
						:title="option.value"
						@click="onCheck(option)"
					>
						<span>{{option.value}}</span>
					</div>
				</div>

                <div class="select-dropdown__btn-container">
                    <BaseButton
                        class="select-dropdown__apply"
                        view="secondary"
                        form="oval"
                        @click="onApply"
                    >Ок</BaseButton>
                </div>
			</div>
		</template>
	</BaseDropdown>
</template>

<script>
import BaseButton from "./BaseButton"
import BaseDropdown from "./BaseDropdown"

export default {
	name: "BaseSelectMultiple",
	components: {
		BaseButton,
		BaseDropdown
	},
	props: {
		/**
		 * @property {Array} options - массив опций
		 * @property {Array} value - выбранное значение по умолчанию
         * @property {String|Number} defaultTitle
         * @property {Boolean} canSearch - флаг включения поиска по списку
         * @property {Boolean} open - флаг активности списка
         * @property {Boolean} isMultiple - флаг мультивыбора
		 */
		options: {
			type: Array,
			default: () => []
		},
		value: {
			type: [String, Number, Array],
			default: null
		},
		defaultTitle: {
			type: [String, Number],
			default: 'Выбрать значение',
		},
		canSearch: {
			type: Boolean,
			default: false
		},
		open: {
			type: Boolean,
			default: false
		},
        isMultiple: {
            type: Boolean,
            default: true
        },
        isFullWidth: {
            type: Boolean,
            default: false,
        },
        styleListDropdown: {
            type: Object,
            default: () => {},
        },
	},
	data() {
		/**
		 * @property {Boolean} localOpen - условие отображение опций
         * @property {String} searchValue - строка фильтрации
         * @property {Array} valueBuffer - буфер для мультивыбора
		 */
		return {
			searchValue: '',
			localOpen: this.open,
            valueBuffer: []
		}
	},
	computed: {
		/**
		 * Вернет title для селекта
		 */
		title() {
			const currentOption = this.options.find(i => i.id === this.value) || {}
			return currentOption.value || this.defaultTitle
		},
		/**
		 * Массив значений по ключу поиска
		 */
		filteredOptions() {
			return this.options.filter(item => {
				const optionValue = String(item.value).toLowerCase()
				const searchValue = String(this.searchValue).toLowerCase()

				return optionValue.startsWith(searchValue)
			})
		}
	},
	created() {
		// Сработает при эмите события cancel в родителе
		this.$parent.$on('$closeSelect', this.onClose)
	},
	methods: {
		updateSearchValues(value){
			this.searchValue = value;
		},
		/**
		 * @returns {Object} классы для опций
		 */
		getOptionClasses(option) {
			if (Array.isArray(this.valueBuffer)) {
				return {
					'select-options__item_active': this.valueBuffer.includes(option.id)
				}
			}

			return {
				'select-options__item_active': this.valueBuffer === option.id
			}
		},
        /**
         * close
         */
        onApply() {
            if(this.isMultiple){
                this.$emit('check', this.valueBuffer)
            }
            this.$emit('$closeDropdown')
            this.$emit('close')
        },
		/**
		 * close
		 */
		onClose() {
			this.$emit('$closeDropdown')
			this.$emit('close')
		},
		/**
		 * @param {Object} option - option по которому был совершен клик
		 */
		onCheck(option) {
            if(!this.isMultiple){
                this.value = this.valueBuffer = [option.id];
                this.$emit('check', this.value)
                // this.$emit('$closeDropdown')
                this.$emit('close')
                return true;
            }
            if(this.valueBuffer.length === 1 && this.valueBuffer.includes(option.id)) {
                return true;
            }
            if(this.valueBuffer.includes(option.id)){
                this.valueBuffer = this.valueBuffer.filter(item => item !== option.id)
            }else {
                this.valueBuffer.push(option.id)
            }
		},
		onDropdown(event) {
			this.localOpen = event;
			this.valueBuffer = this.value;
		},
        onSelectAll() {
            const valueItems = this.options.map((el) => el.id);
            this.valueBuffer = valueItems;
        },
        onClear() {
            this.valueBuffer = [];
        },
	}
}
</script>

<style lang="scss" scoped>
$primary-color: #F0F3F8 !default;
$success-color: #6EC87A !default;
$primary-disabled-color: #DCE1E8 !default;

.select {
	display: inline-block;
	position: relative;
	width: 130px;
	user-select: none;
	outline: none;
	font-size: 13px;

    &--full-width {
        width: 100%;
    }

	&-preview {
		padding: 6px 15px;
		border: 1px solid transparent;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		transition: .2s;
		text-align: left;

        &--full-width {
            padding: 10px 20px;
            font-size: 14px;
            font-weight: 500;
        }

		&__label {
			flex-grow: 1;

            font-size: 12px;
            font-weight: 400;
            color: #000;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
		}

		&__arrow {
			transform: rotate(-90deg);
			width: 7px;
			margin-left: 10px;
			flex-shrink: 0;

			img { width: 100%; }
		}
	}

	&-dropdown {
		max-height: 350px;
		overflow: hidden;
		display: flex;
		flex-direction: column;

        &__btn-container {
            display: flex;
            justify-content: flex-end;
        }

        &__apply {
            margin-top: 10px;
        }

		&__search {
			width: 100%;
			cursor: pointer;
			border-radius: inherit;
			transition: .1s;

			input {
				border: none;
				border-bottom: 1px solid $primary-disabled-color;
				background: transparent;
				height: 34px;
				width: 100%;
				display: block;
				outline: none;
				padding-left: 16px;

				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath id='iconmonstr-magnifier-2' d='M10,9.019,7.394,6.434a4.012,4.012,0,0,0,.78-2.38A4.087,4.087,0,0,0,0,4.054,4.092,4.092,0,0,0,6.391,7.4L9.011,10,10,9.019ZM1.2,4.054A2.888,2.888,0,1,1,4.087,6.92,2.88,2.88,0,0,1,1.2,4.054Z' fill='%23b9bbc2'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position: center left;
				background-size: 10px;
				font-size: 12px;

				&::placeholder {
					color: $primary-disabled-color !important;
				}
			}
		}

		&__header {
			flex-shrink: 0;
			flex-grow: 0;
		}
		&__top {
			margin-bottom: 4px;
		}
		&__footer {
			flex-shrink: 0;
			flex-grow: 0;
			margin-top: 20px;
		}
	}

	&-options {
		flex-grow: 1;
		overflow: auto;
		margin-top: 10px;
		padding-right: 10px;

		&__item {
			border: 1px solid transparent;
			max-width: 100%;
			cursor: pointer;
			border-radius: 400px;
			transition: .1s;
			padding: 8px 15px;

			&:hover:not(.select-options__item_active) {
				background: $primary-color;
			}

			span {
				white-space: nowrap;
				display: block;
				width: 90%;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}

			&_active {
				border: 1px solid $success-color;
				color: $success-color;
			}
		}
	}

    .dropdown--full-width {
        width: 100%;
    }

}
</style>
