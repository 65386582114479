import Vue from 'vue'
import App from './App.vue'

import store from './store'
import router from './router'
import axios from 'axios'
import VTooltip from 'v-tooltip'
import Clipboard from 'v-clipboard'
import vClickOutside from 'v-click-outside'

import "./assets/scss/main.scss";

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.use(VTooltip)
Vue.use(Clipboard)
Vue.use(vClickOutside)
window.vueRouter = router;
new Vue({
	render: h => h(App),
	store,
	router,
}).$mount('#app')
