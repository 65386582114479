<template>
	<div ref="chips" class="chips">
		<div
			v-for="(chipsItem, index) of chipsToShow"
			:key="index"
			class="chips__item"
            v-tooltip.bottom="showTooltip(chipsItem)"
            :style="customStyle"
		>
			{{ chipsItem.value }}
			<span
				class="chips__item-close"
				@click="onRemoveChips(chipsItem)"
			>
				<BaseIcon icon="icon-close" class="chips__item-close-icon" />
			</span>
		</div>

		<button
			v-if="hiddenChipsCounter > 0"
			class="chips__btn"
			@click="onMore"
		>Еще {{ hiddenChipsCounter }}</button>

	</div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon'

export default {
	name: "Chips",
	components: {
        BaseIcon,
	},

    /**
     * Входные данные компонента
     * @property {Array} chips - Список чипсов
     * @property {Object} customStyle - Кастомные стили для одного чипса
     * @property {Number} numberDisplayedChips - Количество чипсов, отображаемых по умолчанию
     */
	props: {
        chips: {
            type: Array,
            default: () => []
        },
        customStyle: {
            type: Object,
            default: () => {},
        },
        numberDisplayedChips: {
            type: Number,
            default: 2,
        },
        maxWidth: {
          type: Number,
          default: 0,
        },
        maxHeight: {
            type: Number,
            default: 0,
        },
	},

    computed: {
        /**
         * Список чипсов которые будут отображаться
         */
        chipsToShow() {
            return this.chips.slice(0, Number(this.numberDisplayedChips));
        },
        /**
         * @returns {Number} Количество скрытых чипсов
         */
        hiddenChipsCounter() {
            return this.chips.length - Number(this.numberDisplayedChips);
        },
	},
	methods: {
        /**
         * Показать тултип
         */
        showTooltip(item) {
            if (this.customStyle?.overflow === 'hidden' ) {
                return item.value;
            }

            return '';
        },
        /**
         * Клик на кнопку удаления
         * @property  {Object} chipsItem - элемент для удаления
         */
		onRemoveChips(chipsItem) {
			this.$emit('remove', chipsItem);
		},
        /**
         * Клик на кнопку показа скрытых чипсов
         */
		onMore() {
			this.$emit('more');
		},
	}
}
</script>

<style lang="scss" scoped>
$active-color: #4A92F6 !default;
$primary-color: #F0F3F8 !default;

.chips {
	$marginRight: 5px;

    display: flex;

    %child {
		white-space: nowrap;
		display: inline-block;
		font-size: 12px;
		padding: 5px 10px;
		border-radius: 1000px;
		cursor: pointer;
        box-sizing: border-box;
	}

	&__item {
		@extend %child;
		background: $active-color;
		color: #fff;
		position: relative;

		&:not(:last-child) {
			margin-right: $marginRight;
		}

		&:hover .chips__item-close {
			z-index: 0;
			opacity: 1;
		}
	}

    &__item-close {
        width: 25px;
        height: 25px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        z-index: -1;
        opacity: 0;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: .2s;
    }

    &__item-close-icon {
        font-size: 12px;
        color: $active-color !important;
    }

	&__btn {
		@extend %child;
		margin-right: $marginRight;
		background: $primary-color;
		color: #B9BBC2;
		cursor: pointer;
	}
}
</style>
