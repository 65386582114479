<template>
	<div class="filter-item" ref="item">
        <div v-if="isLoading" class="filter-item__preloader">
            <base-preloader-gif-v2 />
        </div>
		<div class="filter-item__wrapper">
			<div class="filter-item__title">{{ title }}</div>
			<button
				class="sign"
				@click="onTransfer"
			>
                <span class="sign__icon icon-close"></span>
			</button>
			<div class="options">
                <div class="options__btn-wrap">
                    <BaseButton
                        v-if="isShowSelectAll"
                        view="simple"
                        class="options__btn"
                        @click="onSelectAll"
                    >
                        Выбрать все
                    </BaseButton>
                    <BaseButton
                        v-if="isShowUnselectAll"
                        view="simple"
                        class="options__btn"
                        @click="onUnselectAll"
                    >
                        Убрать все
                    </BaseButton>
                </div>
				<div
					v-for="(option, index) of options"
					:key="index"
				>
					<button
						class="options__option"
						:class="getOptionClasses(option)"
						@click="onCheck(option)"
					>
						{{ option.value }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BasePreloaderGifV2 from "@/components/Base/BasePreloaderGifV2"
import BaseButton from '@/components/Base/BaseButton'
export default {
	name: "FilterItem",
	components: {
        BasePreloaderGifV2,
        BaseButton
	},
	props: {
		id: [Number, String],
		title: {
			type: String,
			default: ""
		},
		options: {
			type: Array,
			default: () => []
		},
		selected: {
			type: Array,
			default: () => []
		},
        isLoading: {
			type: Boolean,
			default: false
		},
	},
    computed: {
        isShowSelectAll() {
            return this.options.length > this.selected.length;
        },
        isShowUnselectAll() {
            return this.selected.length >= 1;
        }
    },
	methods: {
        onSelectAll() {
            const checkData = {
                filterItemId: this.id,
                optionIds: this.options.map((option) => {
                    const {id = '-1'} = option;
                    return String(id);
                })
            };
            this.$emit('onSelectAll', checkData);
        },
        onUnselectAll() {
            this.$emit('onUnselectAll', this.id);
        },
		onCheck(optionData) {
			const body = {
				filterItemId: this.id,
				optionId: optionData.id
			}

			this.$emit('check', body)
		},
		/**
		 * @param {Object} optionData - объект с данными опции
		 * @returns {Object} - классы для опций
		 */
		getOptionClasses(optionData) {
			const {id, canCheck} = optionData
			return {
				'options__option--active': this.selected.includes(id),
				'options__option--disabled': !canCheck
			}
		},
		onTransfer() {
			this.$emit('transfer', this.id)
		}
	}
}
</script>

<style lang="scss" scoped>
$active-color: #4A92F6 !default;
$primary-color: #F0F3F8 !default;
$primary-lighten-color: #F9FAFB !default;

.filter-item {
	height: 250px;
	padding: 3px;
	border-radius: 10px;
	// box-shadow: 0 0 0px 3px #EFF1F4;
    position: relative;
    &__preloader {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: white;
        top: 0;
        left: 0;
        opacity: 0.7;
    }

	&__wrapper {
		height: 100%;
		position: relative;
		width: 100%;
		background: #fff;
		padding: 15px 0 5px 15px;
		border-radius: inherit;
		border: 1px solid #F2F4F5;
		//overflow: auto;
	}

	&:hover .sign__icon {
		opacity: 1;
	}

	&__title {
		white-space: nowrap;
		cursor: default;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		flex-grow: 1;
		font-weight: 500;
		border-bottom: 1px solid #F2F4F5;
		padding-bottom: 6px;
	}

	.options {
        max-height: 92%;
        overflow: auto;
		display: flex;
		flex-direction: column;

        &__btn-wrap {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-top: 6px;
        }

        &__btn {
            font-size: 12px;
            font-weight: 400;
            color: #B9BBC2;

            text-align: left;
            margin-right: 6px;
            margin-bottom: 3px;
            padding: 5px 10px;
            border-radius: 300px;
            border: 1px solid #F2F4F5;
            transition: all ease-out .5s;

            &:hover {
                border: 1px solid #6EC87A;
                color: #6EC87A;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__option {
            display: inline-block;
            font-size: 12px;
            margin-top: 6px;
            padding: 5px 10px;
            border-radius: 300px;
            cursor: pointer;
            background: $primary-color;
            transition: .2s;
            text-align: left;

            &:hover:not(.options__option--active) {
                // background: #DCE1E8;
                background: $primary-lighten-color;
            }

            &--active {
                background: $active-color;
                color: #fff !important;
            }

            &--disabled {
                color: #ccc;
            }
        }

	}

	.sign {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		color: #b9bbc2;
		width: 34px;
		height: 34px;
		position: absolute;
		top: 5px;
		right: 0px;
        transition: all ease-out .4s;

        &:hover, &:active {
            color: #333;
        }

		&__icon {
			width: 10px;
			opacity: 0;
            transition: opacity ease-out .5s;
		}
	}
}
</style>
