import axios from 'axios';
import ServiceToken from "./ServicesAuth/ServiceToken";
import ServiceAuth from "./ServicesAuth/ServiceAuth";
/* eslint-disable no-extra-semi */
/**
 * Класс для работы с апи
 */
export default class ServiceApi {
    /**
     * Конфигурация аxios
     * @static
     * @property {Object} free - конфигурация аxios для микросервисасервиса свободы
     * @property {Object} demo - конфигурация axios для микросервиса демо
     * @property {Object} catalog - конфигурация axios для микросервиса каталога
     * @property {Object} reconciliation - конфигурация axios для микросервиса сверок
     */
    static api() {
        let headers = {};
        if (window.serviceShare !== null && typeof(window.serviceShare) !== 'undefined') {
            headers = {
                'X-Auth-Token': window.serviceShare.shareProductToken,
                'X-Share-Token': window.serviceShare.shareToken
            }
        }
        else
            headers = {'X-Auth-Token': ServiceToken.productToken}
        return {
            free: axios.create({
                baseURL: process.env.VUE_APP_FREE_BACKEND_HOST,
                headers: headers
            }),
            demo: axios.create({baseURL: process.env.VUE_APP_DEMO_BACKEND_HOST}),
            catalog: axios.create({baseURL: process.env.VUE_APP_CATALOG_BACKEND_HOST}),
            reconciliation: axios.create({baseURL: process.env.VUE_APP_RECONCILIATION_BACKEND_HOST})
        };
    }

    /**
     * Гет-запрос
     * @static
     * @async
     * @param {String} microserviceName - название микросервиса (ключ объекта api)
     * @param {String} route - маршрут
     * @param {Object} params - параметры гет запроса
     * @param {Object} reqConfig - хедеры запроса
     * @returns {Object}
     */
    static async get(microserviceName = '', route = "", params = {}, reqConfig = {}) {
        const api = this.api();
        try {
            const response = await api[microserviceName].get(route, params, reqConfig);
            return response;
        }
        catch(error) {
            this.createError(microserviceName, error);
        }
    }

    /**
     * Пост-запрос
     * @static
     * @async
     * @param {String} microserviceName - название микросервиса (ключ объекта api)
     * @param {String} route - маршрут
     * @param {Object} postData - тело пост-запроса
     * @param {Object} reqConfig - хедеры запроса
     * @returns {Object}
     */
    static async post(microserviceName = '', route = "", postData = {}, reqConfig = {}) {
        const api = this.api();
        try {
            let response = await api[microserviceName].post(route, postData, reqConfig);
            return response;
        }
        catch(error) {
            this.createError(microserviceName, error);
        }
    }

    /**
     * Обработать ошибку
     * @static
     * @async
     * @param {String} microserviceName - название микросервиса (ключ объекта api)
     * @param {Object} error - бъект ошибки
     */
    static async createError(microserviceName = '', error = {}) {
        console.log(error);
        if (microserviceName === 'free') {
            const {response = {}} = error;
            const {status = -1} = response;
            if (status === 401) {
                await ServiceAuth.logout();
            }
        }
        throw new Error(error);
    }
};
