/**
 * Схема для тулбара карты
 * @property {Array} toolbarBlocks - блоки тулбара
 */
const SchemeToolbar = {
    toolbarBlocks: [
        // {
        //     key: 'map',
        //     items: [
        //         {
        //             id: 'zoomIni',
        //             icon: 'icon-plus',
        //             description: '',
        //             emit: 'onZoomIni'
        //         },
        //         {
        //             id: 'zoomOuti',
        //             icon: 'icon-minus',
        //             description: '',
        //             emit: 'onZoomOuti'
        //         }
        //     ],
        // },
        {
            key: 'zoom',
            items: [
                {
                    id: 'zoomIn',
                    icon: 'icon-plus',
                    description: '',
                    emit: 'onZoomIn'
                },
                {
                    id: 'zoomOut',
                    icon: 'icon-minus',
                    description: '',
                    emit: 'onZoomOut'
                }
            ]
        }
    ]
};
export default SchemeToolbar;