<template>
	<div class="filter-panel">
        <BaseSelect
            class="filter-panel__select"
            view="primary"
            position="left"
            :options="cities"
            :value="selectedCities"
            :canSearch="true"
            :isCloseAfterCheck="false"
            :defaultTitle="cityTitle"
            @check="onCheckCity"
        >
            <template #header>
                <span class="filter-panel__select-title">Выберите город</span>
            </template>
            <!-- <template #footer>
                <div class="filter-panel__btn-container">
                    <BaseButton
                        class="filter-panel__cancel"
                        view="secondary"
                        form="oval"
                        @click="onClose"
                    >Ок</BaseButton>
                </div>
            </template> -->
        </BaseSelect>

        <BaseDropdown
            class="filter-panel__date-wrapper"
            position="left"
            ref="datepicker"
            :padding="0"
            @changeVisible="onChangeVisibleDatepicker"
        >
            <template #preview>
                <BaseButton
                    class="filter-panel-date"
                    :active="isDatepickerOpen"
                >
                    <BaseSvgIcon name="calendar" class="filter-panel-date__icon" />
                    <span class="filter-panel-date__title">{{dateTitle}}</span>
                </BaseButton>
            </template>

            <template #content>
                <DatePicker
                    :isMultiple="true"
                    :accessDates="accessDates"
                    :checkedDates="checkedDates"
                    @cancel="$emit('$closeDropdown')"
                    @approve="onApproveDatepicker"
                />
            </template>
        </BaseDropdown>

        <div class="filter-info">
            <div
                class="filter-info__wrapper"
                :class="{ getFilterButtonClasses, 'filter-info__wrapper--resizing' :isResize }"
            >
                <BaseButton
                    class="filter-info-btn"
                    :active="isFilterOpen"
                    @click="onFilterToggle"
                >
                    <BaseSvgIcon name="filter" width="14px" />
                    <span>Фильтр</span>
                </BaseButton>

                <div
                    ref="chipsWrap"
                    class="filter-info__chips-wrapper"
                    :class="{'filter-info__chips-wrapper--resizing' : isResize}">
                    <div
                        class="filter-info__chips-place"
                        v-if="hasChips"
                        :class="{'filter-info__chips-place--resizing' : isResize}"
                    >
                        <AppChips
                            ref="chips"
                            :class="{'chips--resizing' : isResize}"
                            :chips="chips"
                            :max-width="maxWidthChips"
                            :max-height="maxHeightChips"
                            :number-displayed-chips="countVisibleChips"
                            @remove="onRemoveChips"
                            @more="onFilterToggle"
                        />
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import BaseSelect from '@/components/Base/BaseSelect'
import BaseSvgIcon from '@/components/Base/BaseSvgIcon'
import BaseButton from '@/components/Base/BaseButton'
import BaseDropdown from '@/components/Base/BaseDropdown'
import DatePicker from '@/components/Base/DatePicker'
import AppChips from './Chips'

export default {
	name: "FilterPanel",
	components: {
		BaseSelect,
        BaseSvgIcon,
        BaseButton,
        BaseDropdown,
        DatePicker,
        AppChips
	},
    props: {
        chips: {
            type: Array,
            default: () => []
        },
        checkedDates: {
            type: Array,
            default: () => []
        },
        accessDates: {
            type: Array,
            default: () => []
        },
        cities: {
            type: Array,
            default: () => []
        },
        selectedCities: {
            type: Array,
            default: () => []
        },
        isFilterOpen: {
            type: Boolean,
            default: false
        }
    },

    mounted() {
        this.isResize = true;
        let resizeTimeout = 0;
        this.loadClientWidth = document.documentElement.clientWidth;

        window.addEventListener('resize', () => {
            if (!resizeTimeout) {
                resizeTimeout = setTimeout(() => {
                    resizeTimeout = null;
                    this.getWrapperWidth();
                }, 300);
            }
        }, false);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.getWrapperWidth);
    },

    watch: {
        chips() {
            if (this.chips.length > 0) {
                this.getVisibleChips();
            }
        },
    },

    data() {
        return {
            months: [
				'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
				'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'
			],
            monthsFull: new Map(Object.entries({
                'Янв': 'Январь',
                'Фев': 'Февраль',
                'Мар': 'Март',
                'Апр': 'Апррель',
                'Май': 'Май',
                'Июн': 'Июнь',
				'Июл': 'Июль',
                'Авг': 'Август',
                'Сен': 'Сентябрь',
                'Окт': 'Октябрь',
                'Ноя': 'Ноябрь',
                'Дек': 'Декабрь',
            })),
            isDatepickerOpen: false,
            maxWidthChips: 0,
            maxHeightChips: 0,
            countVisibleChips: 2,
            isResize: false,
            loadClientWidth: 0,
        }
    },
    computed: {
        /**
         * @returns {String} - заголовок для select
         */
        cityTitle() {
            if (this.selectedCities.length === 0) {
                return 'Выберите город'
            } else if (this.selectedCities.length === 1) {
                const selectedCityId = this.selectedCities[0]
                const selectedCity = this.cities.find(item => String(item.id) === String(selectedCityId))
                return selectedCity.value
            } else {
                return `Выделено: ${this.selectedCities.length}`
            }
        },
        /**
         * заголовок для копки даты
         * @returns {String}
         */
        dateTitle() {
            if (this.checkedDates.length === 0) {
                return 'Выберите дату'
            } else {
                let uniqueMonths = []

                this.checkedDates.forEach(item => {
                    uniqueMonths.push(item.month)
                    uniqueMonths = Array.from(new Set(uniqueMonths))
                })
                uniqueMonths = uniqueMonths.map(item => this.months[item-1])
                const result = uniqueMonths.join(', ')

                return this.checkedDates.length === 1 ? this.monthsFull.get(String(result)): `${ result }`;
            }
        },
        /**
         * @returns {Boolean}
         */
        hasChips() {
            return this.chips.length > 0
        },
        getFilterButtonClasses() {
            return {'filter-info__wrapper_has': this.hasChips}
        }
    },
	methods: {
        onChangeVisibleDatepicker(boolean) {
            this.isDatepickerOpen = boolean
        },
        /**
         * включаю/выключаю фильтр
         */
		onFilterToggle() {
			this.$emit('toggle')
		},
        /** -
         * Выбрал город
         * @param {Object} event
         */
        onCheckCity(event) {
            const {id: cityId} = event

            const body = {
                filterItemId: 'city_id',
                value: [cityId]
            }

            this.$emit('checkCity', body)
            this.onClose();
        },
        /** -
         * @param {Array} event - массив объектов в формате {year: 2022, month: 6}
         */
        onApproveDatepicker(event) {
            this.$emit('$closeDropdown')
            this.$emit('approveDatepicker', event)
        },
        /**
         * @param {Object} chipsData
         * событие удаления чипса
         */
        onRemoveChips(chipsData) {
            this.$emit('removeChips', chipsData);
        },
        /**
         * Поиск по выделенным городам
         */
        onClose() {
            // для прослушки события в компонентк BaseSelect
            this.$emit('$closeSelect')
        },
        /**
         * Найти количество отображаемых chips в текущей ширине контейнера
         */
        getVisibleChips(isReducedWidth = false) {
            this.maxWidthChips = this.$refs.chipsWrap.clientWidth;
            // this.maxHeightChips = this.$refs.chipsWrap.clientHeight;

            const paddingChip = 20;
            const paddingChips = 30;
            const marginChips = 5;
            // const moreButtonWidth = 53 + marginChips;
            const oneSymbolWidth = this.chips.length > 5 ? 7.2 : 7.4;
            let chipsWidth = 0;
            let chipsCount = 0;
            let reduceConstant = 0;

            if (isReducedWidth) {
                reduceConstant = 39;
            }

            for (const chip of this.chips) {
                const chipWidth = Math.round((chip.value.length * oneSymbolWidth) + paddingChip);
                if ((chipsWidth + chipWidth) >= (this.maxWidthChips - paddingChips - reduceConstant)) {
                    // console.log('a', chipsWidth + (reduceConstant / 2))
                    // console.log('b', this.maxWidthChips - paddingChips - reduceConstant)

                    if (chipsWidth + (reduceConstant / 3) >= this.maxWidthChips - paddingChips - reduceConstant) {
                        if (chipsCount > 0) {
                            chipsCount--;
                            break;
                        }
                    }
                    break;
                } else {
                    chipsWidth += Math.round(chip.value.length * oneSymbolWidth) + paddingChip;
                    if (chipsCount > 1) {
                        chipsWidth += marginChips;
                    }
                    chipsCount++;
                }
            }

            if (chipsCount === 0) {
                chipsWidth = 0;
            }

            // console.log('chipsCount', chipsCount)
            this.countVisibleChips = chipsCount;

        },
        /**
         * При событии Resize вызвать функцию пересчета кол-ва отображаемых тегов
         */
        getWrapperWidth() {
            let isReduced = false;
            const resizedWidth = document.documentElement.clientWidth;
            if (resizedWidth < this.loadClientWidth) {
                isReduced = true;
            }
            this.isResize = true;
            this.loadClientWidth = resizedWidth;
            this.getVisibleChips(isReduced);
        },
	},
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default; //#6EC87A !default
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.filter-panel {
	display: flex;
	align-items: center;
    height: 40px;

    &__select-title {
        font-weight: 700;
        font-size: 14px;
    }
    &__btn-container {
        display: flex;
        justify-content: flex-end;
    }

    & > div:not(:first-child) {
        margin-left: 10px;
    }

    &__select {
        height: 100%;
        flex-shrink: 0;

        .select-preview {
            height: 100%;
        }
    }

    &-date {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        height: 100%;
        width: 180px;
        border-radius: 100px;
        padding: 0 15px;
        text-align: left;

        &__icon {
            flex-shrink: 0;
        }

        &__title {
            display: block;
            margin-left: 10px;
            flex-grow: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &__date-wrapper {
        height: 100%;
    }

    .filter-info {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        border: 1px solid #F2F4F5;
        border-radius: 1000px;

        &__wrapper {
            width: 100%;
            height: 100%;
            border-radius: 1000px;
            display: flex;

            &_has {
                border: 1px solid $primary-color;
            }

            &--resizing {
                align-items: center;
            }
        }

        &__chips-wrapper {
            display: flex;
            width: 100%;

            &--resizing {
                height: 25px;
                overflow: hidden;
            }
        }

        &__chips-place {
            display: flex;
            align-items: center;
            padding: 0 15px;

            &--resizing {
                align-items: flex-start;
            }

            .chips {
                position: relative;
                padding-right: 65px;
            }

            .chips--resizing {
                flex-wrap: wrap;
            }
        }

        &-btn {
            height: 100%;
            border-radius: 1000px;
            display: flex;
            align-items: center;
            padding: 0 22px 0 15px;

            &_active {
                background: #fff;
                border-color: $primary-color;
            }

            span {
                display: block;
                margin-left: 10px;
            }
        }
    }
}

.filter-panel-date.btn_active,
.filter-info-btn.btn_active {
    border-color: #6EC87A;
}
</style>

<style lang="scss">
.filter-info__chips-place .chips__btn {
    position: absolute;
    top: 0;
    right: 0;
}

.filter-panel {
    .filter-panel__select {
        width: 180px!important;

        .select-preview {
            height: 100%;
            border-radius: 100px!important;

        }
        .select-preview.btn_active {
            border-color: #6EC87A!important;
        }
        .select-preview__label {
            font-weight: 700;
        }
    }
    .d-wrap__content-wrapper {
        height: 100%;
    }
}
</style>
